import React, {useState, useEffect} from 'react';

import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {db, analytics} from '../firebase/firebase';
import Footer from './parts/footer';
import cx from 'clsx';
import Header from './parts/header';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {ImageListItem, ImageList} from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import {useFadedShadowStyles} from '@mui-treasury/styles/shadow/faded';
import {useGutterBorderedGridStyles} from '@mui-treasury/styles/grid/gutterBordered';

import {compose, lifecycle, withHandlers, withState} from 'recompose';
import Notification from './Notification';

import LegendReservas from './dashboard/components/LegendReservas';
import {Grid} from '@mui/material';
import CardScheduled from './CardScheduled';
import GeolocationScreen from './Location/GeolocationScreen';
import {CircularProgress} from '@material-ui/core';
import axios from 'axios';

const renderNotificacao = (notification, i) => {
	// console.log(notification)
	return <Notification key={i} timeout={5000} title={notification.title} options={notification.options} />;
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},
	button: {
		background: '#f7f7f7',
		margin: '2px',
		padding: '9px 15px',
		borderRadius: '4px',
		border: '1px solid #ddd'
	},
	buttonSeguiente: {
		margin: '2px',
		borderRadius: '4px'
	},
	actionsContainer: {
		marginBottom: theme.spacing(2)
	},
	resetContainer: {
		padding: theme.spacing(3)
	},
	toggleContainer: {
		margin: theme.spacing(2, 0)
	},
	radioButton: {
		color: '#000'
	},
	image: {
		position: 'relative',
		height: 200,
		[theme.breakpoints.down('xs')]: {
			width: '100% !important', // Overrides inline-style
			height: 100
		},
		'&:hover, &$focusVisible, &.selected': {
			zIndex: 1,
			'& $imageMarked': {
				opacity: 0
			},
			'& $imageTitle': {
				border: '4px solid currentColor'
			}
		}
	},
	focusVisible: {},
	imageButton: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: '0 6px 10px rgba(0,0,0,0.14)',
		borderBottom: '2px solid #ebebeb',
		border: '3px solid #fff',
		borderRadius: '10px',
		margin: 10,
		color: theme.palette.common.white
	},
	imageSrc: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		boxShadow: '0 6px 10px rgba(0,0,0,0.14)',
		borderBottom: '2px solid #ebebeb',
		border: '3px solid #fff',
		borderRadius: '10px',
		margin: 10,
		backgroundSize: 'contain ',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center'
	},
	imageBackdrop: {
		position: 'absolute',
		left: 0,
		right: 0,
		borderRadius: '25px',
		top: 0,
		bottom: 0,
		margin: 10,
		opacity: 0.4,
		transition: theme.transitions.create('opacity')
	},
	imageTitle: {
		position: 'relative',
		padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`
	},
	imageMarked: {
		height: 3,
		width: 18,
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		bottom: -2,
		left: 'calc(50% - 9px)',
		transition: theme.transitions.create('opacity')
	},
	gridroot: {
		flexGrow: 1
	},
	alternativeLabel: {
		color: 'rgba(63, 81, 181, 1)',
		fontWeight: '500',
		fontSize: 20
	},
	iconContainer: {
		float: 'left',
		display: 'flex !important'
	},
	completed: {
		fontWeight: '500',
		textDecoration: 'line-through',
		color: 'rgba(63, 81, 181, 1) !important'
	},
	active: {
		color: 'rgba(63, 81, 181, 1) !important'
	},
	labelContainer: {
		background: '#f7f7f7',
		margin: '2px',
		padding: '9px 15px',
		borderRadius: '4px',
		color: 'rgba(63, 81, 181, 1)',
		border: '1px solid #ddd'
	},
	paper: {
		// padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary
	},
	cardroot: {
		minWidth: 220,
		maxWidth: 260,
		boxShadow: '0 4px 6px rgba(0,0,0,0.14)',
		border: '3px solid #eee',
		borderTop: '1px solid #eee',
		borderRight: '1px solid #eee',
		borderBottom: '1px solid #eee',
		borderRadius: '10px',
		display: 'block',
		textAlign: 'center',
		background: '#fff',
		borderLeftStyle: 'solid',
		borderLeftWidth: '5px',
		borderLeftColor: '#2ecc71',
		'-webkit-transition': 'all .4s ease-out',
		transition: ' all .4s ease-out',
		'&:hover': {
			boxShadow: '0 6px 10px rgba(0,0,0,0.14)'
		}
	},
	proximosDias: {
		borderLeftColor: '#d0dfe7'
	},
	acabouNumeros: {
		borderLeftColor: '#ff0000'
	},
	aindaTem: {
		borderLeftColor: '#2ecc71'
	},
	parahoje: {
		borderLeftColor: '#f29b72'
	},
	avatar: {
		margin: 'auto'
	},
	heading: {
		fontSize: 18,
		fontWeight: 'bold',
		letterSpacing: '0.5px',
		marginTop: 8,
		marginBottom: 0
	},
	subheader: {
		fontSize: 14,
		color: '#808080'
		// marginBottom: '0.875em',
	},
	statLabel: {
		fontSize: 12,
		color: '#808080',
		fontWeight: 500,
		fontFamily:
			'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
		margin: 0
	},
	statValue: {
		fontSize: 20,
		fontWeight: 'bold',
		marginBottom: 0,
		letterSpacing: '1px'
	},
	statValue1: {
		fontSize: 13,
		fontWeight: 'bold',
		marginBottom: 0,
		lineHeight: 1,
		letterSpacing: '1px'
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)'
	},
	title: {
		fontSize: 14
	},
	pos: {
		marginBottom: 12
	},
	ImageListItemRoot: {
		height: '254px'
	},
	boxLegend: {
		marginTop: '0.5rem'
	},
	imageLegend: {
		// position: 'relative',
		width: '50%',
		[theme.breakpoints.down('xs')]: {
			width: '100% !important' // Overrides inline-style
		}
	},
	bairro: {
		fontSize: 12,
		color: '#808080',
		marginBottom: '0.875em'
	}
}));

const Inicial = (props) => {
	const history = useHistory();
	const [value, setValue] = useState('1');
	let [farmacias, setFarmacias] = useState([]);
	let [dadosFarm, setdadosFarm] = useState([]);
	let [farmaciaID, setFarmaciaID] = useState('');
	let [reservasFarmacia, setReservasFarmacia] = useState([]);
	let [horaCerta, sethoraCerta] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [scheduledFixed, setScheduledFixed] = useState();
	const [loading, setLoading] = useState(false);
	const [dataPharm, setDataPharm] = useState();

	var weekday = new Array(7);
	weekday[0] = 'lunes';
	weekday[1] = 'martes';
	weekday[2] = 'miércoles';
	weekday[3] = 'jueves';
	weekday[4] = 'viernes';
	weekday[5] = 'sábado';
	weekday[6] = 'domingo';

	const getSteps = () => {
		return ['Elige la región que quieres reservar', 'Ahora elige la farmacia'];
	};

	const navigateReservar = (params) => {
		history.replace(params);
	};

	useEffect(() => {
		const getUsers = () => {
			db.collection('usuarios')
				.where('mostrar', '==', true)
				.orderBy('nome', 'asc')
				.onSnapshot(function (querySnapshot) {
					const tempDoc = querySnapshot.docs.map((doc, idx) => {
						return {id: doc.id, ...doc.data()};
					});
					setFarmacias(tempDoc);
				});
		};
		getUsers();
	}, []);

	const handleFarmacia = async (id) => {
		setLoading(true);
		getPharmacyData(id);
		setFarmaciaID(id);
		await getAgendamentosFarmaciaID(id);
	};


	const fetchTimestampFromServer = async () => {
		try {
			const response = await axios.get('https://us-central1-reservar-cannabis-uru.cloudfunctions.net/widgets/getTimeStamp');
			return response.data.timestamp;
		} catch (err) {
			console.log('ERR => ', err);
		}
	};

	const getAgendamentosFarmaciaID = async (item) => {
		const timestamp = await fetchTimestampFromServer();
		try {
			db.collection('usuarios')
				.doc(item)
				.collection('agendamentos')
				.where('dataFimRet', '>=', new Date(timestamp))
				.orderBy('dataFimRet', 'asc')
				.onSnapshot(async (querySnapshot) => {
					let dados = [];
					let docs = querySnapshot.docs;
					for (let ag of docs) {
						const obj = {
							_id: item,
							dataInicioRet: ag.data().dataInicioRet,
							dataFimRet: ag.data().dataFimRet,
							reservaID: ag.id,
							reservas: 0,
							numeros: 0,
							horarioFim:
								('0' + new Date(ag.data().dataInicioRet.seconds * 1000).getDate()).slice(-2) +
								'/' +
								('0' + (new Date(ag.data().dataInicioRet.seconds * 1000).getMonth() + 1)).slice(-2) +
								'\n ' +
								new Date(ag.data().dataInicioRet.seconds * 1000).toLocaleTimeString('es-ES', {
									hour: '2-digit',
									minute: '2-digit'
								}) +
								'/' +
								new Date(ag.data().dataFimRet.seconds * 1000).toLocaleTimeString('es-ES', {hour: '2-digit', minute: '2-digit'}),
							data: ag.data().startDate.toDate(),
							ativa: ag.data().resAtiva,
							products: ag.data().products,
							fixed: ag.data().fixed
						};
						const products = ag.data().products;
						const total = products.reduce((acumulador, produto) => {
							return acumulador + parseInt(produto.qtde);
						}, 0);
						// console.log('TOTAL PRODUTOS AGENDAMENTO => ', total);

						let res = await getReservasID(ag.id);
						// console.log('RES => ', res);
						obj.numeros = total;
						obj.reservas = res;
						dados.push(obj);
					}
					handleCards(dados);
					checkDate(dados);
				});
		} catch (error) {
			setLoading(false);
			// console.log('ERROR => ', error);
		}
	};

	const checkDate = (dados) => {
		const diaHoje = new Date(dados.data);
		const diaAmanha = new Date();
		diaHoje.setHours(0, 0, 0, 0);
		diaAmanha.setHours(0, 0, 0, 0);
		diaHoje.setDate(diaHoje.getDate());
		diaAmanha.setDate(diaAmanha.getDate());
	};

	const getReservasID = async (id) => {
		try {
			let reservas = new Promise((resolve) => {
				db.collection('reservas')
					.where('reservaID', '==', id)
					.onSnapshot(async (querySnapshot) => {
						// console.log("SNAP RESERVA");
						let docs = querySnapshot.docs;
						let can = 0;
						let res = 0;
						let ret = 0;
						let total = 0;
						docs.forEach(function (d) {
							if (d.data().status === 'reservado') {
								let reservados = d.data().products.reduce((acumulador, produto) => {
									return acumulador + produto.qtde;
								}, 0);
								console.log('RESERVADOS => ', reservados);
								res += reservados;
							}

							if (d.data().status === 'cancelado') {
								let cancelados = d.data().products.reduce((acumulador, produto) => {
									return acumulador + produto.qtde;
								}, 0);
								console.log('CANCELADOS => ', cancelados);
								can += cancelados;
							}

							if (d.data().status === 'retirado') {
								let retirados = d.data().products.reduce((acumulador, produto) => {
									return acumulador + produto.qtde;
								}, 0);
								console.log('RETIRADOS => ', retirados);
								ret += retirados;
							}

							total =
								total +
								d.data().products.reduce((acumulador, produto) => {
									return acumulador + produto.qtde;
								}, 0);
						});
						console.log('TOTAL DE PRODUTOS DAS RESERVAS => ', total, res, can, ret);
						resolve(total - res - ret + can);
						// resolve(res + ret);
					});
			});
			reservas.then(function (result) {
				return result;
			});
			return reservas;
		} catch (error) {
			setLoading(false);
			// console.log('ERROR => ', error);
		}
	};

	const getPharmacyData = async (id) => {
		const farmaciaRef = db.collection('usuarios');
		const doc = await farmaciaRef.doc(id).get();

		let obj = {
			farmaciaID: doc.id,
			farmaciaNome: doc.data().nome,
			farmaciaLogo: doc.data().logo,
			farmaciaRua: doc.data().endereco.rua,
			farmaciaCidade: doc.data().endereco.cidade,
			farmaciaBairro: doc.data().endereco.bairro,
			farmaciaStatus: doc.data().mostrar
		};

		setDataPharm(obj);
		setdadosFarm(doc.data());
		analytics.logEvent('inicio_selecionou_' + doc.data().nome);
	};

	const handleCards = (dados) => {
		let dataFarmacia = [];

		dados.forEach((data, idx) => {
			let btn = false;
			let green = false;
			let red = false;
			let gray = false;
			let orange = false;

			let horaAtual = parseInt((new Date().getTime() / 1000).toFixed(0));
			let horaReserva = parseInt((data.data.getTime() / 1000).toFixed(0));

			let dataAtual = parseInt((new Date().getTime() / 1000).toFixed(0));
			let dataReserva = parseInt((data.data.getTime() / 1000).toFixed(0));

			let today = new Date();
			let otherDate = data.data;
			let isToday = today.toDateString() === otherDate.toDateString();

			let numeros = parseInt(data.numeros);
			let reservas = data.reservas;

			let horaExata = horaCerta;

			if (!data.ativa) {
				red = true;
			} else {
				if (isToday) {
					if (horaAtual >= horaReserva) {
						if (numeros > reservas) {
							btn = true;
							green = true;
						} else if (numeros <= reservas) {
							red = true;
						}
					} else {
						orange = true;
					}
				} else if (dataReserva > dataAtual) {
					gray = true;
				} else if (dataReserva < dataAtual) {
					if (numeros >= reservas) {
						btn = true;
						green = true;
					} else if (numeros < reservas) {
						red = true;
					}
				}
			}
			const obj = {
				btn: btn,
				green: green,
				red: red,
				gray: gray,
				orange: orange,
				...data
			};

			if (!!data.fixed) {
				setScheduledFixed(obj);
			}

			if (!data.fixed) {
				dataFarmacia.push(obj);
			}
		});
		setReservasFarmacia(dataFarmacia);
		setLoading(false);
		setActiveStep(2);
	};

	const diaHoje = new Date();
	const diaAmanha = new Date();
	diaHoje.setHours(0, 0, 0, 0);
	diaAmanha.setHours(0, 0, 0, 0);
	diaAmanha.setDate(diaAmanha.getDate() + 1);

	const ButtonFarmacia = ({farmacia}) => {
		return (
			<ButtonBase
				focusRipple
				onClick={() => handleFarmacia(farmacia.id)}
				key={farmacia.nome}
				className={classes.image}
				focusVisibleClassName={classes.focusVisible}
				style={{
					width: '200px'
				}}
			>
				<div
					className={classes.imageSrc}
					style={{
						backgroundImage: `url(${farmacia.backImg})`
					}}
				/>
				<div className={classes.imageBackdrop} />
				<div className={classes.imageButton}></div>
			</ButtonBase>
		);
	};

	const steps = getSteps();

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const handleChange = (event) => {
		setValue(event.target.value);
	};

	const getStepContent = (step) => {
		// console.log('GETSTEPCONTENT => ', step);
		switch (step) {
			case 0:
				return (
					<FormControl component="fieldset">
						<RadioGroup
							style={{justifyContent: 'center'}}
							row
							aria-label="region"
							name="region"
							value={value}
							onChange={handleChange}
						>
							<FormControlLabel
								value="1"
								control={<Radio color="primary" />}
								label="Montevideo"
								classes={{label: classes.radioButton}}
							/>
							<FormControlLabel
								value="2"
								control={<Radio color="primary" />}
								label="Interior"
								classes={{label: classes.radioButton}}
							/>
							<FormControlLabel
								value="3"
								control={<Radio color="primary" />}
								label="Cerca de mí"
								classes={{label: classes.radioButton}}
							/>
						</RadioGroup>
					</FormControl>
				);
			case 1:
				if (value === '3') {
					return <GeolocationScreen pharmacies={farmacias} onClick={handleFarmacia} loading={loading} onBack={handleBack} />;
				}
				let mensagem = [];
				if (farmacias.length !== 0) {
					mensagem = [];
					if (!loading) {
						farmacias.forEach(function (documents) {
							// console.log(documents);
							if (value === documents.endereco.regiao || documents.endereco.regiao === '3') {
								mensagem.push(<ButtonFarmacia key={documents.nome} farmacia={documents} />);
							}
						});
					} else {
						mensagem.push(<CircularProgress style={{margin: 30}} color="secondary" />);
					}
				} else {
					mensagem.push(`No hay farmacias.`);
				}
				//mensagem.push(`No hay farmacias.`);
				if (mensagem !== null) {
					return <div>{mensagem}</div>;
				} else {
					break;
				}
			case 2:
				return ' teste 2';
			case 3:
				return ' teste 3';
			case 4:
				return ' teste 4';

			default:
				return 'Unknown step';
		}
	};
	const classes = useStyles();

	const shadowStyles = useFadedShadowStyles();
	const borderedGridStyles = useGutterBorderedGridStyles({
		borderColor: 'rgba(0, 0, 0, 0.08)',
		height: '50%'
	});

	return (
		<div>
			<Header />
			{props.notifications && props.notifications.length > 0 && props.notifications.map(renderNotificacao)}
			<div className="page-header header-filter" data-parallax="true" style={{backgroundImage: `url('/img/chamada-teste-1.jpg'`}}>
				<div className="container">
					<div className="row">
						<div className="col-md-8 ml-auto mr-auto">
							<div className="brand left">
								<div className="cover-inner container text-center">
									<h1 className="jumbotron-heading">
										Cannabis<br></br>
										<strong>sin</strong>
										<em>fila</em>
									</h1>
									<p className="lead">Elegí la farmacia y reservá online!</p>
									<p>
										<a className="menu__item btn btn-primary btn-lg mb-2 mr-2 ml-2 reservar" href="#numeros">
											RESERVAS DISPONIBLES
										</a>
										<a className="menu__item btn btn-outline-white btn-lg mb-2 ml-2 ml-2" href="#como">
											COMO FUNCIONA?
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="main main-raised">
				<div className="container" id="numeros">
					<div className="section text-center">
						<h3 className="title">ASISTENTE DE RESERVA</h3>

						<div>
							<Stepper activeStep={activeStep} orientation="vertical">
								{steps.map((label, index) => (
									<Step key={index}>
										<StepLabel
											classes={{
												alternativeLabel: classes.alternativeLabel,
												active: classes.active,
												completed: classes.completed,
												iconContainer: classes.iconContainer,
												label: classes.alternativeLabel,
												iconLabel: classes.iconLabel
											}}
										>
											{label}
										</StepLabel>
										<StepContent>
											<Typography component="span">{getStepContent(index)}</Typography>
											<div className={classes.actionsContainerzzz}>
												<div>
													<br />
													{index !== 0 && (
														<Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
															Volver
														</Button>
													)}

													{activeStep !== steps.length - 1 && (
														<Button
															variant="contained"
															color="primary"
															onClick={handleNext}
															classes={{label: classes.buttonSeguiente}}
														>
															Siguiente
														</Button>
													)}
												</div>
											</div>
										</StepContent>
									</Step>
								))}
								<Step key={2}>
									<StepLabel
										classes={{
											alternativeLabel: classes.alternativeLabel,
											active: classes.active,
											completed: classes.completed,
											iconContainer: classes.iconContainer,
											label: classes.alternativeLabel,
											iconLabel: classes.iconLabel
										}}
									>
										Elige la fecha de la reserva
									</StepLabel>
									<StepContent>
										{activeStep === steps.length &&
											(farmaciaID === '' ? (
												<Paper square elevation={0} className={classes.resetContainer}>
													<p>No elegiste una farmacia</p>
													<Button onClick={handleReset}>Elegir Farmacia</Button>
												</Paper>
											) : (
												<Paper square elevation={0} className={classes.resetContainer}>
													<div className={classes.gridroot}>
														<p className={classes.pos} color="textPrimary">
															<img
																style={{
																	borderRadius: '25px'
																}}
																width="180px"
																alt="logo"
																src={dadosFarm.logo}
															/>
														</p>
														{/* <h5>Aviso: El sistema del IRCCA se encuentra caído momentáneamente, por esta razón las reservas pueden ser cambiadas o canceladas.
                              Podes saber más desde los detalles de la reserva y en el mail que enviamos.
                            </h5> */}

														<ImageList actionposition="" id="gridList" className={classes.gridList} cols={5}>
															{!reservasFarmacia.length && !scheduledFixed && (
																<Card key={1} className={cx(classes.cardroot, shadowStyles.root, classes.parahoje)}>
																	<CardContent>
																		<Button className={classes.avatar} variant="contained" color="primary" size="small" disabled>
																			INDISPONIBLE
																		</Button>
																		<h3 className={classes.heading}>No hay reservas aún</h3>
																		{dadosFarm.endereco !== undefined && <span className={classes.subheader}></span>}
																	</CardContent>
																	<Divider light />
																	<Box display={'flex'}>
																		<Box p={2} flex={'auto'} className={borderedGridStyles.item}>
																			<p className={classes.statLabel}>Indisponible</p>
																		</Box>
																		<Box p={2} flex={'auto'} className={borderedGridStyles.item}>
																			<p className={classes.statLabel}>Indisponible</p>
																		</Box>
																	</Box>
																</Card>
															)}
															{/* {loading && (<h1>Loading....</h1>)} */}
															<CardScheduled scheduledFixed={scheduledFixed} dataPharm={dataPharm} />

															{reservasFarmacia.map((item, index) => (
																<ImageListItem
																	key={index}
																	style={{minWidth: '280px', height: '35em !important', overflow: 'unset'}}
																>
																	{item.green ? (
																		<Card className={cx(classes.cardroot, shadowStyles.root, classes.aindaTem)}>
																			<CardContent>
																				{index < 2 && item.btn ? (
																					<Button
																						className={classes.avatar}
																						variant="contained"
																						color="primary"
																						size="small"
																						onClick={() =>
																							navigateReservar({
																								pathname: '/queue',
																								// pathname: '/reserva/' + dadosFarm.nome + '/' + ("0" + item.data.getDate()).slice(-2) + "-" + ("0" + (item.data.getMonth() + 1)).slice(-2),
																								state: {
																									agendamentoID: item.reservaID,
																									products: item.products,
																									idfarmacia: farmaciaID,
																									farmaciaNome: dadosFarm.nome,
																									date: new Date(),
																									iframe: false
																								}
																							})
																						}
																					>
																						RESERVAR
																					</Button>
																				) : (
																					<Button
																						className={classes.avatar}
																						variant="contained"
																						color="primary"
																						size="small"
																						disabled
																					>
																						RESERVAR
																					</Button>
																				)}

																				<h3 className={classes.heading}>{dadosFarm.nome}</h3>

																				{dadosFarm.endereco !== undefined && (
																					<div>
																						<span className={classes.subheader}>{dadosFarm.endereco.cidade}</span>
																						<span className={classes.bairro}>{dadosFarm.endereco.bairro}</span>
																					</div>
																				)}

																				<Grid container flexDirection={'column'}>
																					<div style={{display: 'flex', flexDirection: 'row'}}>
																						{item.products &&
																							item.products.map((item, idx) => (
																								<div
																									key={idx}
																									style={{
																										display: 'flex',
																										flexDirection: 'column',
																										justifyContent: 'center',
																										flexWrap: 'wrap',
																										alignItems: 'center',
																										marginLeft: 5,
																										marginRight: 5
																									}}
																								>
																									<span
																										style={{background: item.color, width: 15, height: 15, borderRadius: 50}}
																									></span>
																									{/* <span style={{ fontSize: 11, fontWeight: '400', marginLeft: 3, textAlign: 'center', }}>{item.qtde}</span> */}
																									<span
																										style={{
																											fontSize: 10,
																											fontWeight: '400',
																											marginLeft: 5,
																											lineHeight: '13px',
																											textAlign: 'center',
																											marginTop: 5
																										}}
																									>
																										{item.text}
																									</span>
																								</div>
																							))}
																					</div>
																				</Grid>
																			</CardContent>
																			<Divider light />
																			<Box display={'flex'}>
																				<Box p={2} flex={'auto'} className={borderedGridStyles.item}>
																					<p className={classes.statLabel}>Disponible</p>
																					<p className={classes.statValue1}>
																						{('0' + item.data.getDate()).slice(-2) +
																							'/' +
																							('0' + (item.data.getMonth() + 1)).slice(-2) +
																							'\n' +
																							item.data.toLocaleTimeString('es-ES', {hour: '2-digit', minute: '2-digit'})}
																					</p>
																				</Box>
																				<Box p={2} flex={'auto'} className={borderedGridStyles.item}>
																					<p className={classes.statLabel}>Retirada</p>
																					<p className={classes.statValue1}>{item.horarioFim}</p>
																				</Box>
																			</Box>
																		</Card>
																	) : (
																		''
																	)}
																	{/* {proximosDias acabouNumeros aindaTem parahoje} */}
																	{item.orange ? (
																		<Card key={index} className={cx(classes.cardroot, shadowStyles.root, classes.parahoje)}>
																			{/* {console.log("OPT 3")} */}

																			<CardContent>
																				<Button
																					className={classes.avatar}
																					variant="contained"
																					color="primary"
																					size="small"
																					disabled
																				>
																					RESERVAR
																				</Button>
																				<h3 className={classes.heading}>{dadosFarm.nome}</h3>
																				{dadosFarm.endereco !== undefined && (
																					<div>
																						<span className={classes.subheader}>{dadosFarm.endereco.cidade}</span>
																						<span className={classes.bairro}>{dadosFarm.endereco.bairro}</span>
																					</div>
																				)}

																				<Grid container flexDirection={'column'}>
																					<div style={{display: 'flex', flexDirection: 'row'}}>
																						{item.products &&
																							item.products.map((item, idx) => (
																								<div
																									key={idx}
																									style={{
																										display: 'flex',
																										flexDirection: 'column',
																										justifyContent: 'center',
																										flexWrap: 'wrap',
																										alignItems: 'center',
																										marginLeft: 5,
																										marginRight: 5
																									}}
																								>
																									<span
																										style={{background: item.color, width: 15, height: 15, borderRadius: 50}}
																									></span>
																									{/* <span style={{ fontSize: 11, fontWeight: '400', marginLeft: 3, textAlign: 'center', }}>{item.qtde}</span> */}
																									<span
																										style={{
																											fontSize: 10,
																											fontWeight: '400',
																											marginLeft: 5,
																											lineHeight: '13px',
																											textAlign: 'center',
																											marginTop: 5
																										}}
																									>
																										{item.text}
																									</span>
																								</div>
																							))}
																					</div>
																				</Grid>
																			</CardContent>

																			<Divider light />
																			<Box display={'flex'}>
																				<Box p={2} flex={'auto'} className={borderedGridStyles.item}>
																					<p className={classes.statLabel}>Disponible</p>
																					<p className={classes.statValue1}>
																						{('0' + item.data.getDate()).slice(-2) +
																							'/' +
																							('0' + (item.data.getMonth() + 1)).slice(-2) +
																							'\n' +
																							item.data.toLocaleTimeString('es-ES', {hour: '2-digit', minute: '2-digit'})}
																					</p>
																				</Box>
																				<Box p={2} flex={'auto'} className={borderedGridStyles.item}>
																					<p className={classes.statLabel}>Retirada</p>
																					<p className={classes.statValue1}>{item.horarioFim}</p>
																				</Box>
																			</Box>
																		</Card>
																	) : (
																		''
																	)}

																	{item.red ? (
																		<Card key={index} className={cx(classes.cardroot, shadowStyles.root, classes.acabouNumeros)}>
																			<CardContent>
																				<Button
																					className={classes.avatar}
																					variant="contained"
																					color="primary"
																					size="small"
																					disabled
																				>
																					RESERVAR
																				</Button>
																				<h3 className={classes.heading}>{dadosFarm.nome}</h3>
																				{dadosFarm.endereco !== undefined && (
																					<div>
																						<span className={classes.subheader}>{dadosFarm.endereco.cidade}</span>
																						<span className={classes.bairro}>{dadosFarm.endereco.bairro}</span>
																					</div>
																				)}

																				<Grid container flexDirection={'column'}>
																					<div style={{display: 'flex', flexDirection: 'row'}}>
																						{item.products &&
																							item.products.map((item, idx) => (
																								<div
																									key={idx}
																									style={{
																										display: 'flex',
																										flexDirection: 'column',
																										justifyContent: 'center',
																										flexWrap: 'wrap',
																										alignItems: 'center',
																										marginLeft: 5,
																										marginRight: 5
																									}}
																								>
																									<span
																										style={{background: item.color, width: 15, height: 15, borderRadius: 50}}
																									></span>
																									{/* <span style={{ fontSize: 11, fontWeight: '400', marginLeft: 3, textAlign: 'center', }}>{item.qtde}</span> */}
																									<span
																										style={{
																											fontSize: 10,
																											fontWeight: '400',
																											marginLeft: 5,
																											lineHeight: '13px',
																											textAlign: 'center',
																											marginTop: 5
																										}}
																									>
																										{item.text}
																									</span>
																								</div>
																							))}
																					</div>
																				</Grid>
																			</CardContent>
																			<Divider light />
																			<Box display={'flex'}>
																				<Box p={2} flex={'auto'} className={borderedGridStyles.item}>
																					<p className={classes.statLabel}>Disponible</p>
																					<p className={classes.statValue1}>
																						{('0' + item.data.getDate()).slice(-2) +
																							'/' +
																							('0' + (item.data.getMonth() + 1)).slice(-2) +
																							'\n' +
																							item.data.toLocaleTimeString('es-ES', {hour: '2-digit', minute: '2-digit'})}
																					</p>
																				</Box>
																				<Box p={2} flex={'auto'} className={borderedGridStyles.item}>
																					<p className={classes.statLabel}>Retirada</p>
																					<p className={classes.statValue1}>{item.horarioFim}</p>
																				</Box>
																			</Box>
																		</Card>
																	) : (
																		''
																	)}

																	{item.gray ? (
																		<Card key={index} className={cx(classes.cardroot, shadowStyles.root, classes.proximosDias)}>
																			<CardContent>
																				<Button
																					className={classes.avatar}
																					variant="contained"
																					color="primary"
																					size="small"
																					disabled
																				>
																					RESERVAR
																				</Button>
																				<h3 className={classes.heading}>{dadosFarm.nome}</h3>
																				{dadosFarm.endereco !== undefined && (
																					<div>
																						<span className={classes.subheader}>{dadosFarm.endereco.cidade}</span>
																						<span className={classes.bairro}>{dadosFarm.endereco.bairro}</span>
																					</div>
																				)}
																				<Grid container flexDirection={'column'}>
																					<div style={{display: 'flex', flexDirection: 'row'}}>
																						{item.products &&
																							item.products &&
																							item.products.map((item, idx) => (
																								<div
																									key={idx}
																									style={{
																										display: 'flex',
																										flexDirection: 'column',
																										justifyContent: 'center',
																										flexWrap: 'wrap',
																										alignItems: 'center',
																										marginLeft: 5,
																										marginRight: 5
																									}}
																								>
																									<span
																										style={{background: item.color, width: 15, height: 15, borderRadius: 50}}
																									></span>
																									{/* <span style={{ fontSize: 11, fontWeight: '400', marginLeft: 3, textAlign: 'center', }}>{item.qtde}</span> */}
																									<span
																										style={{
																											fontSize: 10,
																											fontWeight: '400',
																											marginLeft: 5,
																											lineHeight: '13px',
																											textAlign: 'center',
																											marginTop: 5
																										}}
																									>
																										{item.text}
																									</span>
																								</div>
																							))}
																					</div>
																				</Grid>
																			</CardContent>

																			<Divider light />
																			<Box display={'flex'}>
																				<Box p={2} flex={'auto'} className={borderedGridStyles.item}>
																					<p className={classes.statLabel}>Disponible</p>
																					<p className={classes.statValue1}>
																						{('0' + item.data.getDate()).slice(-2) +
																							'/' +
																							('0' + (item.data.getMonth() + 1)).slice(-2) +
																							'\n' +
																							item.data.toLocaleTimeString('es-ES', {hour: '2-digit', minute: '2-digit'})}
																					</p>
																				</Box>
																				<Box p={2} flex={'auto'} className={borderedGridStyles.item}>
																					<p className={classes.statLabel}>Retirada</p>
																					<p className={classes.statValue1}>{item.horarioFim}</p>
																				</Box>
																			</Box>
																		</Card>
																	) : (
																		''
																	)}
																</ImageListItem>
															))}
														</ImageList>

														<p style={{marginTop: '280px', textAlign: 'center'}}>
															<img
																src="https://blog.reservarcannabis.com/wp-content/uploads/2023/03/swipe.gif"
																width="80"
																alt="Puedes navegar entre las fechas deslizando el dedo hacia la derecha o hacia la izquierda"
															/>
															<br></br>
															<small>
																Puedes navegar entre las fechas deslizando el dedo hacia la derecha o hacia la izquierda.
															</small>
														</p>
													</div>
													<Button variant="contained" onClick={handleReset}>
														Elegir Otra Farmacia
													</Button>
												</Paper>
											))}
									</StepContent>
								</Step>
							</Stepper>
						</div>
						{activeStep === steps.length && <LegendReservas />}
					</div>
					<div className="row text-center" id="como">
						<div className="col-md-10 ml-auto mr-auto">
							<h2 className="title">Como Reservar?</h2>
							<h4>Si ya sos un Adquirente, es muy fácil!</h4>
							<p></p>
							<div className="embed-responsive embed-responsive-16by9">
								<iframe
									className="embed-responsive-item"
									src="https://www.youtube.com/embed/lE5AUbsFN08"
									frameBorder="0"
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen=""
								></iframe>
							</div>
							<p></p>
							<div className="row">
								<div className="col-md-4 col-feature">
									<div className="rounded-circle justify-center">
										<img src="/img/icons/icone-calendario-reservarcannabis.png" alt="Elegí la fecha disponible" width="65" />
									</div>
									<h5 className="title">1. Elegí la fecha disponible</h5>
								</div>
								<div className="col-md-4 col-feature">
									<div className="rounded-circle justify-center">
										<img src="/img/icons/icone-llenar-los-campos-reservar-cannabis.png" alt="Completá el Formulário" width="65" />
									</div>
									<h5 className="title">2. Completá el Formulário</h5>
								</div>
								<div className="col-md-4 col-feature">
									<div className="rounded-circle justify-center">
										<img src="/img/icons/icone-download-reservarcannabis.png" width="65" alt="Descargá la Reserva" />
									</div>
									<h5 className="title">3. Descargá la Reserva</h5>
								</div>
								<small>
									* Los Adquirentes son aquellas personas que están habilitadas para adquirir cannabis. Se requiere por ley el
									registro firmado directamente con el Instituto de Regulación y Control Del Cannabis (IRCCA), el Correo Uruguayo
									brinda el servicio de registro de adquirentes que quieren comprar cannabis en las Farmacias que venden Marihuana
									en Uruguay -{' '}
									<a href="https://blog.reservarcannabis.com/preguntas-frecuentes/" title="Preguntas Frecuentes">
										Hacé click aqui para saber más.
									</a>
								</small>
								<br></br>
							</div>
							<br></br>
							<div className="adheridas">
								<h3 className="title">Farmacias Disponibles</h3>
								<p>Farmacias que venden Marihuana en Uruguay</p>
								{farmacias &&
									farmacias.map((item, index) => (
										<a
											key={index}
											target="_blank"
											title={`Hace click aqui para Reservar el Cannabis en ${item.nome} - Ubicada en ${item.endereco.bairro} - ${item.endereco.provincia} - ${item.endereco.cidade} `}
											href={`https://farmacias.reservarcannabis.com/${item.permalink}`}
										>
											{' '}
											<img
												src={item.logo}
												alt={`${item.nome} - Farmacias que vende Marihuana en ${item.endereco.bairro} - ${item.endereco.provincia} - ${item.endereco.cidade} `}
												title={`Reserve Cannabis en ${item.nome} - Ubicada en ${item.endereco.bairro} - ${item.endereco.provincia} ${item.endereco.cidade} - Uruguay - Comprar Cannabis `}
												width="100"
											/>
										</a>
									))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />

			{/* </body> */}
			{/* <Fab onClick={askForPermissioToReceiveNotifications} size="small" color="secondary" aria-label="add" style={{ position: "fixed", bottom: 10, left: 10, zIndex: 9999 }}>
        <IconNotifications />
      </Fab> */}
		</div>
	);
};

export default compose(
	withState('token', 'setToken', ''),
	withState('notifications', 'setNotifications', []),
	withHandlers({
		pushNotification:
			({setNotifications, notifications}) =>
			(newNotification) =>
				setNotifications(notifications.concat(newNotification))
	}),
	lifecycle({
		async componentDidMount() {
			const {pushNotification, setToken} = this.props;
		}
	})
)(Inicial);
